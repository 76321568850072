// Author: Josef Heitzler
// Contact: josef.heitzler@gmx.de 
// Date: 26 Aug 2022
import { Button, Input, TextField } from '@mui/material';
import React from 'react';
import { insertPlayer } from '../api/requests';

interface IPanelAddPlayerProps {
    handleChange: () => void;
}

interface IPanelAddPlayerState {
    playerInput: string;
}

export default class PanelAddPlayer extends React.Component<IPanelAddPlayerProps, IPanelAddPlayerState> {

    public constructor(props: IPanelAddPlayerProps) {
        super(props);
        this.state = {
            playerInput: ""
        }
    }

    private async insertPlayer(name: string){
        let player = name;
        this.setState({
            playerInput: ""
        })
        await insertPlayer(player).then(
            (response) => {
                console.log(response)
                this.props.handleChange();
        });
    }

    public render() {
        return (
            <div>   
                <TextField 
                    id="outlined-basic" 
                    label="add player" 
                    variant="outlined" 
                    onChange={(event) => this.setState({ playerInput: event.target.value as string})} 
                    value={this.state.playerInput}
                />
                <Button
                    variant="contained" 
                // force reload
                    onClick={() => this.insertPlayer(this.state.playerInput)}
                >
                    add 
                </Button>
            </div>
        );
    }
}
// Author: Josef Heitzler
// Contact: josef.heitzler@gmx.de 
// Date: 5 Sep 2022
import { FormControl, Select, MenuItem, Box, Stack, Typography, Card } from '@mui/material';
import React from 'react';
import { getPlayerHistory } from '../api/requests';
import PanelEloGraph from './PanelEloGraph';
import PanelPlayerHistory from './PanelPlayerHistory';
import { IMatch, IPlayer, IPlayerStats } from '../utils/interfaces';
import PlayerCard from './PlayerCard';

interface IPanelDashboardProps {
    leaderboard: IPlayer[];
}

interface IPanelDashboardState {
    playerStats: IPlayerStats | null;
    playerID: number | null;
}

export default class PanelDashboard extends React.Component<IPanelDashboardProps, IPanelDashboardState> {
    constructor(props: IPanelDashboardProps){
        super(props);
        if (this.props.leaderboard.length > 0)
        {
            this.state = {
                playerID: null,
                playerStats: null
            };
        }
    }

    private handleChange(playerID: number){
        this.loadMatches(playerID);
    }

    private async loadMatches(playerID: number){
        await getPlayerHistory(playerID, 20, 0).then(response => {
            this.setState({
                playerID: playerID,
                playerStats: response
            });
        });
    }

    public render(){
        if ( this.state.playerStats != null )
        {
            return(
                <Box marginTop={4}>
                    <FormControl fullWidth variant="outlined">
                        <Select
                            id="winner-select"
                            value={this.state.playerID}
                            onChange={event => this.handleChange(event.target.value as number)}  
                        >
                            {this.props.leaderboard.map((row: any) => (
                                <MenuItem value={row.id} key={row.id}>{row.name}</MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    { this.state.playerID != null && (
                        <Stack spacing={2} marginBottom={8}>
                            <PlayerCard player={this.state.playerStats.player}/>
                            <Card sx={{
                                width: "100%",
                                height: 200
                            }}>
                                    <PanelEloGraph playerHistory={this.state.playerStats.matches} player={this.state.playerID} />
                            </Card>
                            {/* <PanelMatchups playerMatchups={this.state.playerHistory.matchups} /> */}
                            <PanelPlayerHistory playerHistory={this.state.playerStats.matches} />
                        </Stack>
                    )}
                </Box>
            );    
        }
        else
        {
            this.loadMatches(this.props.leaderboard[0].id);
            return(
                <div>
                    <Typography>No Players</Typography>
                </div>
            );
        }
    };
}
// Author: Josef Heitzler
// Contact: josef.heitzler@gmx.de 
// Date: 05 Sep 2022
import { Box } from '@mui/system';
import React from 'react';

interface ITabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface ITabPanelState {

}

export default class TabPanel extends React.Component<ITabPanelProps, ITabPanelState>{
    constructor(props: ITabPanelProps){
        super(props);
    }

    render() {
        return(
            <div
                role="tabpanel"
                hidden={this.props.value !== this.props.index}
                id={`tab-panel-${this.props.index}`}
                aria-labelledby={`tab-${this.props.index}`}   
            >
                {this.props.index === this.props.value && (
                    <Box>
                        {this.props.children}
                    </Box>
                )}
            </div>
        );
    }
}
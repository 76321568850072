// Author: Josef Heitzler
// Contact: josef.heitzler@gmx.de 
// Date: 25 Aug 2022
import { AppBar, Avatar, Box, Button, FormControl, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import React from 'react';
import { insertMatch } from '../api/requests';
import { IPlayer } from '../utils/interfaces';

interface IPanelAddMatchProps {
    leaderboard: IPlayer[];
    handleChange: () => void;
}

interface IPanelAddMatchState {
    winnerId: number | null;
    loserId: number | null;
    winnerScore: number;
    loserScore: number;
}

export default class PanelAddMatch extends React.Component<IPanelAddMatchProps, IPanelAddMatchState> {

    public constructor(props: IPanelAddMatchProps) {
        super(props);
        if (this.props.leaderboard.length >= 2)
        {
            this.state = {
                winnerId: this.props.leaderboard[0].id,
                loserId: this.props.leaderboard[1].id,
                winnerScore: 0,
                loserScore: 0
            }
        }
        else 
        {
            this.state = {
                winnerId: null,
                loserId: null,
                winnerScore: 0,
                loserScore: 0
            }
        }
    }

    private async insertMatch() {
        let winner = this.state.winnerId;
        let loser = this.state.loserId;
        let winnerScore = this.state.winnerScore;
        let loserScore = this.state.loserScore;
        this.setState({
            winnerId: this.props.leaderboard[0].id,
            loserId: this.props.leaderboard[1].id,
            winnerScore: 0,
            loserScore: 0
        });
        await insertMatch(winner as number, loser as number, winnerScore, loserScore).then(
            (response) => {
                this.props.handleChange();
        });
    }

    private checkScoreInput(input: number, winner: boolean) {
        if (isNaN(input))
            return;

        if (winner) {
            this.setState({ winnerScore: input});
        } else {
            this.setState({ loserScore: input});
        }
    }

    public render() {
        if (this.props.leaderboard.length >= 2) {
            return (
                <Box marginTop={4}>
                    <TableContainer component={Paper}> 
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center"><Typography color={"success"}>WINNER</Typography></TableCell>
                                    <TableCell align="center"><Typography color={"error"}>LOSER</Typography></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <FormControl fullWidth>
                                            <Select
                                                id="outlined-basic"
                                                variant="outlined" 
                                                value={this.state.winnerId}
                                                onChange={event => this.setState({ winnerId: event.target.value as number })}  
                                            >
                                                {this.props.leaderboard.map((row: IPlayer) => (
                                                <MenuItem value={row.id} key={row.id}>{row.name}</MenuItem>
                                            ))}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell>
                                        <FormControl fullWidth>
                                            <Select
                                                id="outlined-basic"
                                                variant="outlined" 
                                                value={this.state.loserId}
                                                onChange={event => this.setState({ loserId: event.target.value as number })}
                                            >
                                                {this.props.leaderboard.map((row: IPlayer) => (
                                                <MenuItem value={row.id} key={row.id}>{row.name}</MenuItem>
                                            ))}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} align="center">
                                        <h3>Score</h3> 
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <TextField 
                                            id="outlined-basic" 
                                            label="winner score" 
                                            variant="outlined" 
                                            type="number"
                                            onChange={(event) => this.checkScoreInput(parseInt(event.target.value), true)} 
                                            value={this.state.winnerScore}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField 
                                            id="outlined-basic" 
                                            label="winner score" 
                                            variant="outlined" 
                                            type="number"
                                            onChange={(event) => this.checkScoreInput(parseInt(event.target.value), false)} 
                                            value={this.state.loserScore}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {(this.state.loserId !== this.state.winnerId && this.state.loserId !== null && this.state.winnerId !== null && this.state.winnerScore >= 11 &&
                    <Box
                        display='flex'
                        justifyContent='center'
                        alignSelf={"vertical"}
                    >
                        <Button
                            variant="contained"
                            // force reload
                            onClick={() => this.insertMatch()}
                        >
                            insert match
                        </Button>
                    </Box>
                    )}
                </Box>
            );
        } else {
            <Typography>
                To few Players found!
            </Typography>
        }
    }
}
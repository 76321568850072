import axios from "axios";

const api = axios.create({
    baseURL: "https://api.maxmanager3000.de",
    withCredentials: true, 
});

export async function getLeaderboard() {
    try {
        let response = await api.get("/Players");
        return response.data;
    } catch (error) {
        console.error("Fehler beim Abrufen des Leaderboards:", error);
        throw error;
    }
}

export async function getPlayerHistory(id: number, limit: number, offset: number){
    try {
        console.log(id);
        let response = await api.get("/PlayerStats", { params: { id: id, limit: limit, offset: offset }});
        return response.data;   
    } catch (error) {
        console.error("Fehler beim Laden der Spielerdaten:", error);
        if (axios.isAxiosError(error)) {
            console.error("Response:", error.response?.data);
            console.error("Status:", error.response?.status);
        }
        throw error;
    }
}

export async function insertPlayer(name: string){ 
    try {
        let response = await api.post(
            "/Players", 
            { name: name }
        );
        return response.data;   
    } catch (error) {
        console.error("Fehler beim Hinzufügen eines neuen Spielers:", error);
        if (axios.isAxiosError(error)) {
            console.error("Response:", error.response?.data);
            console.error("Status:", error.response?.status);
        }
        throw error;
    }
}

export async function insertMatch(winnerId: number, loserId: number, winnerScore: number, loserScore: number){
    try {
        let response = await api.post(
            "/MatchesSingle", 
            { winnerId: winnerId, loserId: loserId, winnerScore: winnerScore, loserScore: loserScore }
        );
        return response.data;  
    } catch (error) {
        console.error("Fehler beim Hinzufügen eines Spiels:", error);
        if (axios.isAxiosError(error)) {
            console.error("Response:", error.response?.data);
            console.error("Status:", error.response?.status);
        }
        throw error;
    }
}

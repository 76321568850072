import React from "react";
import { IPlayer } from "../utils/interfaces";
import { Box, Card, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";

interface IPlayerCardProps {
    player: IPlayer
}

interface IPlayerCardState {

}

export default class PlayerCard extends React.Component<IPlayerCardProps, IPlayerCardState> {

    constructor(props: IPlayerCardProps){
        super(props);
    }

    public render() {   
        return(
            <Card>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Typography component="div">
                                    <Box sx={{ fontWeight: 'bold' }}>Rating</Box>
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>
                                    { this.props.player.ratingSingle.toFixed() }
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell width={"50%"}>
                                <Typography component="div">
                                    <Box sx={{ fontWeight: 'bold' }}>Matches</Box>
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>
                                    { this.props.player.wins + this.props.player.losses }
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography component="div">
                                    <Box sx={{ fontWeight: 'bold' }}>Wins</Box>
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>
                                    { this.props.player.wins }
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography component="div">
                                    <Box sx={{ fontWeight: 'bold' }}>Losses</Box>
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>
                                    { this.props.player.losses }
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Card>
        );
    }
}
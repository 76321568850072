// Author: Josef Heitzler
// Contact: josef.heitzler@gmx.de 
// Date: 8 Sep 2022
import React from 'react';
import { ResponsiveContainer, LineChart, XAxis, YAxis, Tooltip, Legend, Line } from 'recharts';
import { IDataPoint, IMatch } from '../utils/interfaces';

interface IPanelEloGraphProps {
    playerHistory: IMatch[];
    player: number;
}

interface IPanelEloGraphState {

}

export default class PanelEloGraph extends React.Component<IPanelEloGraphProps, IPanelEloGraphState> {
    constructor(props: IPanelEloGraphProps){
        super(props);
    }

    private getScale(data: IDataPoint[]){
        let min: number = 9999;
        let max: number = -1;

        data.forEach(element => {
            if(element.elo > max)
                max = Math.floor(element.elo);
            if(element.elo < min)
                min = Math.floor(element.elo);
        });
        return [min - 64, max + 64];
    }

    public render(){
        let data = [] as IDataPoint[];
        this.props.playerHistory.forEach((element: IMatch) => {
            var winner = element.winnerId == this.props.player;
            data.push({
                "name": winner ? element.winnerName : element.loserName,
                "elo": winner ? element.winnerRating : element.loserRating,
                "timestamp": element.createTime
            });
        });
        return(
            <ResponsiveContainer width="100%" height="100%">
                <LineChart id="line-chart" data={data}
                    margin={{ top: 5, right: 25, left: 0, bottom: 5 }}>
                    <XAxis dataKey="date" />
                    <YAxis type="number" domain={this.getScale(data)}/> 
                    <Tooltip />
                    <Legend />
                    <Line name="rating" type="monotone" dataKey="elo" stroke="#999" dot={true}/>
                </LineChart>
            </ResponsiveContainer>
        );
    }

}
// Author: Josef Heitzler
// Contact: josef.heitzler@gmx.de 
// Date: 22 Aug 2022
import { BottomNavigation, BottomNavigationAction, Box, Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LoadingButton from '@mui/lab/LoadingButton';
import React from 'react';
import { getLeaderboard } from '../api/requests';
import PanelAddDouble from './PanelAddDouble';
import PanelAddMatch from './PanelAddMatch';
import PanelAddPlayer from './PanelAddPlayer';
import PanelDashboard from './PanelDashboard';
import PanelLeaderBoard from './PanelLeaderboard';
import TabPanel from './TabPanel';
import { ILeaderboard, IPlayer } from '../utils/interfaces';

interface IEntryProps {

}

interface IEntryState {
    leaderboard: [IPlayer] | null;
    currentTab: number;
}

export default class Entry extends React.Component<IEntryProps, IEntryState> {

    public constructor(props: IEntryProps) {
        super(props);
        this.state = {
            leaderboard: null,
            currentTab: 0
        }
        this.handleChange = this.handleChange.bind(this);
    }

    private async loadLeaderboard(){
        await getLeaderboard().then(response => {
            this.setState({
                leaderboard: response
            })
        });
    }

    private handleChange(){
        this.loadLeaderboard();
    }

    componentDidMount(){
        this.loadLeaderboard();
    }

    public render() {     
        if(this.state.leaderboard){
            return (
                <Box sx={{ width: '100%' }}>

                    {/* Leaderboard Panel */}
                    <TabPanel index={0} value={this.state.currentTab}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Paper sx={{ position: 'fixed', top: 0, left: 0, right: 0}} elevation={3}>
                                            <Typography variant="h5" margin={2}> TT Leaderboard</Typography>
                                        </Paper>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <PanelLeaderBoard leaderboard={this.state.leaderboard} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TabPanel>

                    {/* Input Panel */}
                    <TabPanel index={1} value={this.state.currentTab}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Paper sx={{ position: 'fixed', top: 0, left: 0, right: 0}} elevation={3}>
                                            <Typography variant="h5" margin={2}>Add match</Typography>
                                        </Paper>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <PanelAddMatch leaderboard={this.state.leaderboard} handleChange={this.handleChange}/>
                                    </TableCell>
                                </TableRow>
                            {/* <Typography variant="h5">Add double</Typography>
                            <PanelAddDouble leaderboard={this.state.leaderboard} handleChange={this.handleChange}/> */}
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h5" margin={2} >Add player</Typography>
                                        <PanelAddPlayer handleChange={this.handleChange}/>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TabPanel>

                    {/* Dashboard Panel*/}
                    <TabPanel index={2} value={this.state.currentTab}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Paper sx={{ position: 'fixed', top: 0, left: 0, right: 0}} elevation={3}>
                                            <Typography variant="h5" margin={2} >Dashboard</Typography>
                                        </Paper>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <PanelDashboard leaderboard={this.state.leaderboard} /> 
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TabPanel>

                    {/* Navigation */}
                    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, height: "7%"}} elevation={3}>
                        <BottomNavigation
                            showLabels
                            value={this.state.currentTab}
                            onChange={(_event: any, value: any) => this.setState({currentTab: value})}
                        >
                            <BottomNavigationAction label="Leaderboard" />
                            <BottomNavigationAction icon={<AddCircleIcon fontSize="large"/>} />
                            <BottomNavigationAction label="Dashboard" />
                        </BottomNavigation>
                    </Paper>
                </Box>
            );
        }else{
            return(
                <div>
                    <LoadingButton loading />
                    Loading ...
                </div>
            );
        }
    }
}
// Author: Josef Heitzler
// Contact: josef.heitzler@gmx.de 
// Date: 8 Sep 2022
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import React from 'react';
import { IMatch } from '../utils/interfaces';
import moment from 'moment';

interface IPanelPlayerHistoryProps {
    playerHistory: IMatch[]
}

interface IPanelPlayerHistoryState {

}

export default class PanelPlayerHistory extends React.Component<IPanelPlayerHistoryProps, IPanelPlayerHistoryState> {
    constructor(props: IPanelPlayerHistoryProps){
        super(props);
    }

    public render(){
        return(
            <TableContainer component={Paper} sx={{ maxHeight: 300 }}>
                <Table size="small" aria-label="a dense table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '30%' }} align="center">winner</TableCell>
                            <TableCell style={{ width: '10%' }} align="center" padding="none">score</TableCell>
                            <TableCell style={{ width: '30%' }} align="center">loser</TableCell>
                            <TableCell style={{ width: '30%' }} align="center">date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.playerHistory.map((row: IMatch) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align="center">{row.winnerName}</TableCell> 
                                <TableCell align="center" padding="none">{row.winnerScore}:{row.loserScore}</TableCell>
                                <TableCell align="center">{row.loserName}</TableCell> 
                                <TableCell align="center">{moment(row.createTime.toString()).format("DD.MM.YYYY")}</TableCell> 
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

}
import './App.css';
import Entry from './components/Entry';

function App() {
  return (
    <div className="App">
      <Entry />
    </div>
  );
}

export default App;

// Author: Josef Heitzler
// Contact: josef.heitzler@gmx.de 
// Date: 25 Aug 2022
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React from 'react';
import { IPlayer } from '../utils/interfaces';

interface IPanelLeaderboardProps {
    leaderboard: IPlayer[]
}

interface IPanelLeaderboardState {
    playersSorted: IPlayer[]
}

export default class PanelLeaderboard extends React.Component<IPanelLeaderboardProps, IPanelLeaderboardState> {
    public constructor(props: IPanelLeaderboardProps) {
        super(props);
        this.state = {
            playersSorted: this.sortedPlayers("rating")
        }
    }

    private sortedPlayers(key: string): IPlayer[] {
        switch(key){
            case "wins":
                return this.props.leaderboard.sort((a: IPlayer, b: IPlayer) => b.wins - a.wins);
            case "losses":
                return this.props.leaderboard.sort((a: IPlayer, b: IPlayer) => b.losses - a.losses);
            case "rating":
                return this.props.leaderboard.sort((a: IPlayer, b: IPlayer) => b.ratingSingle - a.ratingSingle);
            default:
                return this.props.leaderboard;
        }
    }

    public render() {
        if(this.props.leaderboard){
            return (
                <Box marginTop={4}>
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" padding="normal"  variant="head">Name</TableCell>
                                    <TableCell align="center" padding="none"  variant="head">
                                        <Button size="small" onClick={() => this.setState({playersSorted: this.sortedPlayers("wins")})}>
                                            W <ArrowDropDownIcon fontSize="small"/>
                                        </Button>
                                    </TableCell>
                                    <TableCell align="center" padding="none"  variant="head">
                                        <Button size="small" onClick={() => this.setState({playersSorted: this.sortedPlayers("losses")})}>
                                            L <ArrowDropDownIcon fontSize="small"/>
                                        </Button>
                                    </TableCell>
                                    <TableCell align="right" padding="none"  variant="head">
                                        <Button size="small" onClick={() => this.setState({playersSorted: this.sortedPlayers("rating")})}>
                                            Rating <ArrowDropDownIcon fontSize="small"/>
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.playersSorted.map((row: any) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="left">{row.name}</TableCell> 
                                        <TableCell align="center">{parseFloat(row.wins).toFixed()}</TableCell>
                                        <TableCell align="center">{parseFloat(row.losses).toFixed()}</TableCell>
                                        <TableCell align="right">{row.ratingSingle.toFixed()}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            );
        }else{
            return;
        }
    }
}
